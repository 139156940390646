import multicard1 from "./assets/card/multicard1.jpeg";
import multicard10 from "./assets/card/multicard10.jpeg";
import multicard11 from "./assets/card/multicard11.jpeg";
import multicard12 from "./assets/card/multicard12.jpeg";
import multicard13 from "./assets/card/multicard13.jpeg";
import multicard14 from "./assets/card/multicard14.jpeg";
import multicard15 from "./assets/card/multicard15.jpeg";
import multicard16 from "./assets/card/multicard16.jpeg";
import multicard17 from "./assets/card/multicard17.jpeg";
import multicard18 from "./assets/card/multicard18.jpeg";
import multicard19 from "./assets/card/multicard19.jpeg";
import multicard2 from "./assets/card/multicard2.jpeg";
import multicard20 from "./assets/card/multicard20.png";
import multicard3 from "./assets/card/multicard3.jpeg";
import multicard4 from "./assets/card/multicard4.jpeg";
import multicard5 from "./assets/card/multicard5.jpeg";
import multicard6 from "./assets/card/multicard6.jpeg";
import multicard7 from "./assets/card/multicard7.jpeg";
import multicard8 from "./assets/card/multicard8.jpeg";
import multicard9 from "./assets/card/multicard9.jpeg";

// import banner_fb from './assets/banners/facebook_banner2.png';
// import banner_ig from './assets/banners/instagram_banner.png';
import banner_wapp from './assets/banners/whatsapp_banner.png';

export const multiData = [
  multicard1,
  multicard2,
  multicard3,
  multicard4,
  multicard5,
  multicard6,
  multicard7,
  multicard8,
  multicard9,
  multicard10,
];

export const multiData2 = [
  multicard11,
  multicard12,
  multicard13,
  multicard14,
  multicard15,
  multicard16,
  multicard17,
  multicard18,
  multicard19,
  multicard20,
];

export const sliderItems = [
  // {
  //   id: 1,
  //   img: banner_fb,
  //   title: "Siguenos en facebook y conoce más de Miniso",
  //   url: "https://www.facebook.com/MinisoGt/",
  //   desc: "",
  //   bg: ""
  // },
  // {
  //   id: 2,
  //   img: banner_ig,
  //   title: "Visítanos en instagram y se parte de la familia Miniso",
  //   url: "https://www.instagram.com/miniso.gt/?hl=es",
  //   desc: "",
  //   bg: ""
  // },
  {
    id: 1,
    img: banner_wapp,
    title: "Escribenos por whatsapp y conoce nuestros productos",
    url: "https://wa.me/?send=+50241944806&text=Hola!,%20visite%20su%20sitio%20web%20y%20quisiera%20informaci%C3%B3n%20sobre:",
    desc: "",
    bg: ""
  }    
  /*
  {
    id: 1,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/1.png",
    title: "PRIMER SLIDE",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    bg: "f5faf2",
  },
  {
    id: 2,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/2.png",
    title: "SEGUNDO SLIDE",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    bg: "fcf1ed",
  },
  {
    id: 3,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/3.png",
    title: "TERCER SLIDE",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    bg: "fcf1ed",
  },*/
  /*  {
    id: 4,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/accesorios_de_belleza.png",
    title: "CUARTO SLIDE",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    bg: "fcf1ed",
  },
  {
    id: 5,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/accesorios_electronicos.png",
    title: "QUINTO SLIDE",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    bg: "fcf1ed",
  },*/
];

export const categories = [
  {
    id: 1,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/accesorios_de_belleza.png",
    title: "Accesorios de Belleza",
    cat: "04",
  },
  {
    id: 2,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/accesorios_electronicos.png",
    title: "Accesorios Electrónicos",
    cat: "14",
  },
  {
    id: 3,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/bolsas_y_accesorios.png",
    title: "Bolsas y Accesorios",
    cat: "16",
  },
  {
    id: 4,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/fragancias.png",
    title: "Fragancias",
    cat: "01",
  },
  {
    id: 5,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/juguetes.png",
    title: "Juguetes",
    cat: "13",
  },
  {
    id: 6,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/electronicos.png",
    title: "Textil",
    cat: "15",
  },
  {
    id: 7,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/categoria7.png",
    title: "Estilo de Vida",
    cat: "10",
  },
  {
    id: 8,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/categoria8.png",
    title: "Cosméticos",
    cat: "02",
  },
  {
    id: 9,
    img: "https://minisobbs.s3.us-east-2.amazonaws.com/Zoho-admin/categoria9.png",
    title: "Cuidado Personal",
    cat: "03",
  },
];

export const disCountsSection = [
  {
    id: 1,
    img: "/img/discounts/descuento1.png",
    title: "SALUD Y BELLEZA",
  },
  {
    id: 2,
    img: "/img/discounts/descuento2.png",
    title: "DEPORTES",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: 100.0,
  },
  {
    id: 2,
    img: "../../../img/products/6941055122177-1.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 3,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 4,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 5,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 6,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 7,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
  {
    id: 8,
    img: "../../../img/products/4509757451814-2.jpeg",
    title: "Titulo de Productos",
    price: "En esta sección va ubicada la descripción de cada Producto o Item",
  },
];
