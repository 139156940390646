import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.user);
  console.log(isAuthenticated);
  if (!isAuthenticated) {
    return <Redirect to="/login?error=1&message=Debes iniciar sesión" />;
  }
  return <Route {...rest}> {children}</Route>;
};

export default PrivateRoute;
