import React from "react";
import ReactHelmet from "../components/Seo/ReactHelmet";

const Collections = () => {
  return (
    <section>
      <ReactHelmet title={"Colecciones"} />
      <h1>Colecciones</h1>
    </section>
  );
};

export default Collections;
