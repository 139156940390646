import React from "react";
import StorePage from "./Store/StorePage";
import classes from "./TheNew.module.css";

import banner_tiendas from '../assets/section_images/web-tiendas.png'

const Stores = ({ viewStore = false }) => {
  return (
    <section>
      <div className={classes.Container}>
        <div className={classes.ImgContainer}>
          <img
            className={classes.Image}
            src={banner_tiendas}
            alt=''
          />
        </div>
      </div>
      <StorePage noTitle={viewStore} />
    </section>
  );
};

export default Stores;
